import React, { useContext, useState } from 'react';
import { AppContext } from './Context';
import Popup from './Popup';
import Axios from 'axios';
function Backup(props) {
    const context = useContext(AppContext);
    const [backupInfo, setBackupInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const backup = (e, token) => {
        setLoading(true);
        Axios.post("https://backup.api.duc168.com/backup", null, { headers: { token: token}}).then(i => {
            const data = i.data;
            if (data.includes("Token")) {
                setError(true);
                setErrorMessage(JSON.stringify(data));
                setLoading(false);
                return;
            }
            try {
                
                setBackupInfo(data);
                setLoading(false);  
                setError(false);              
                setErrorMessage("");
            } catch (error) {
                setError(true);
                setErrorMessage(JSON.stringify(data));
                setLoading(false);
            }            
        }).catch(i => {
            console.log(i);
            setError(true);
            setErrorMessage("Something went wrong!");
            setLoading(false);
        })
    }

    const logout = (e) => {
        localStorage.removeItem('account');
        context.setState(context, {
            auth: false,
            account: {}            
        });
        setLoading(false);  
        setError(false);              
        setErrorMessage("");
    }
    

    
    return (
        <div>
        { context.auth === false ? <button className="btn btn-primary" onClick={() => context.setState(context, { popup: true, login: true })}>Login</button> : '' }
        { context.auth === true && loading === false ? 
            <>
            <button className="btn btn-success" onClick={(e) => backup(e, context.account.token)}>Backup Now</button>
            <span>  </span>
            <button className="btn btn-danger" onClick={(e) => logout(e)}>Logout</button>
            </>
            
             : '' }
        
        { context.auth === true && loading === true ? 
            <button className="btn btn-success" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Backing up...</span>
            </button>
            : '' }
        <Popup />  
        <hr />
        { !!error ? <div className="p-3 mb-2 bg-warning text-dark">{ errorMessage }</div> : ''}
        { !error && context.auth === true && backupInfo.length > 0 ? 
        
                backupInfo.map(i => {
                    
                    const keyArray = Object.keys(i);
                    const valueArray = Object.values(i);
                    const key = keyArray[0];
                    const value = valueArray[0];
                    const divKey = new Date().getTime() + '' + key; 
                    const success = value.success === true;
                    const status = value.success === true ? 'Backup Successfully' : 'Backup Failed';
                    return (
                        
                         <div key={divKey} className={'alert ' + (success ? 'alert-success' : 'alert-danger')} role="alert">
                         <div><b>{ key.toUpperCase().split('BACKUP')[0] } BACKUP</b></div>
                         <div><i>{ status }</i></div>
                         </div>                            
                        
                    )
                })
             : ''        

        }      
        </div>
    )
}
export default Backup