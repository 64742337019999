import React, { useState } from 'react';
export const AppContext = React.createContext();

let appInitState = {
  auth: false,
  login: false,
  popup: false,    
  account: {}  
}
const cache = localStorage.getItem('account');
  if (cache !== null && cache !== undefined) {
    appInitState = {
      ...appInitState,
      auth: true,
      account: JSON.parse(localStorage.getItem('account'))
    }
  }  

const AppProvider = (props) => {
  const [appState, setAppState] = useState({...appInitState,
    setState: (currentAppState, updateInfo) => {
      setAppState({
        ...currentAppState,
        ...updateInfo
      })
    }});
  return (
    <AppContext.Provider value={appState}>
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider;