import React, { useState, useContext, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { AppContext } from './Context';
import Login from './Login';
function Popup(props) {
    const context = useContext(AppContext);
    const handleClose = () => {        
        context.setState(context, {
            popup: false,
            login: false
        }); 
    }
    const [showLogin, setShowLogin] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    useEffect((a, b) => {        
        setShowLogin(context.login);
        setShowPopup(context.popup);
    })
    // const wrapper = React.createRef();
    return (
      <>
        <Modal show={showPopup} onHide={handleClose}>
        { !!showLogin ?
            <>
            <Modal.Header closeButton>
            <Modal.Title>Login</Modal.Title>
          </Modal.Header>
          <Modal.Body>          
            <Login />          
          </Modal.Body>
            </>
            :
            ''
        }
          
        
        </Modal>
      </>
    );
  }

  export default Popup