import React, { useContext, useState } from 'react';
import Axios from 'axios';
import { AppContext } from './Context';
function Login() {
    const context = useContext(AppContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState("");
    const [loading, setLoading] = useState(false);
    const onSubmit = (e) => {
        !!e ? e.preventDefault() : console.log('No Event is fired!');
        setLoading(true);
        Axios.post("https://account.api.duc168.com/duc-tran-api-account-graphql", { 
        query: `
        query login($username: String!, $password: String!) {
            login(username: $username, password: $password) {
                _id
                token
                username
                firstName
                lastName
                email
                role
            }
        }
        `,
        variables: {
            username: username,
            password: password
        }
        }).then(i => {
            const data = i.data;
            // console.log(data);
            if (data.data == null) {
                setError(true);
                setMsg("Wrong authentication!");
            } else {
                setError(false);
                setMsg("");
                const account = data.data.login;
                localStorage.setItem('account', JSON.stringify(account));
                context.setState(context, { account: account, auth: true, login: false, popup: false });
            }
            setLoading(false);
        }).catch(i => {
            console.log(i);
            setError(true);
            setMsg("Something wrong happens!");
            setLoading(false);
        });
    };
    return (
        <>
        { !loading ? 
            <div>
            <form onSubmit={onSubmit}>
            <div className="form-group">
            <label htmlFor="username">Username</label>
            <input type="text" className="form-control" id="username" value={username} onChange={(e) => setUsername(e.target.value)}/>
            </div>
            <div className="form-group">
            <label htmlFor="password">Password</label>
            <input type="password" className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>            
            <button type="submit" className="btn btn-primary">Submit</button>
            { !!error ? <p style={{ marginTop: '10px', color: 'red'}}>{msg}</p> : ''}
        </form>
        </div>
        : 
        <button className="btn btn-primary" type="button" disabled>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span> Loading...</span>
            </button>
        }
        </>
    )

}
export default Login